import {effect, EventEmitter, inject, Injectable, signal} from '@angular/core';
import {LangChangeEvent, TranslateService} from '@ngx-translate/core';

import {Locale} from '../locale/core/interfaces/locale.interface';


@Injectable({
    providedIn: 'root'
})
export class TranslationService {

    translate = inject(TranslateService)
    // #lang = signal<string>(this.translate.getBrowserLang() || sessionStorage.getItem('language') || 'es');
    #lang = signal('es');


    constructor() {
        this.translate.addLangs(['en', 'es'])
        effect(() => {
            this.translate.use(this.#lang());
            sessionStorage.setItem('language', this.#lang())
        })
    }

    addTranslation(...args: Locale[]): void {
        const locales = [...args];
        locales.forEach(locale =>
            this.translate.setTranslation(locale.lang, locale.data, true));
    }

    getCurrentLang(): string {
        return this.#lang();
    }

    useLang(languageCode: string): void {
        this.#lang.set(languageCode);
    }

    get onLangChange(): EventEmitter<LangChangeEvent> {
        return this.translate.onLangChange;
    }
}
