import {Mapper} from "../../core/base/mapper";
import {UserEntity} from "../entity/user-entity";
import {UserModel} from "../../core/domain/user-model";

export class UserRepositoryMapper implements Mapper<UserEntity, UserModel> {
    mapFrom(param: UserEntity): UserModel {
        return {
            id: param.id,
            name: param.name,
            lastName:param.lastName,
            email: param.email,
            type:param.type,
            state:param.state,
        }
    }

    mapFromList(param: UserEntity[]): UserModel[] {
        const usersModels: UserModel[] = [];
        if (!param){
            return []
        }
        param.forEach( element => {
            usersModels.push(this.mapFrom(element))
        })
        return usersModels;
    }

    mapTo(param: UserModel): UserEntity {
        return {
            id: param.id,
            name: param.name,
            lastName:param.lastName,
            email: param.email,
            type:param.type,
            state:param.state,
        }
    }

    mapToList(param: UserModel[]): UserEntity[] {
        const userEntities: UserEntity[] = [];
        if (!param){
            return []
        }
        param.forEach( element => {
            userEntities.push(this.mapTo(element))
        })
        return userEntities;
    }
}
