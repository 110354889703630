import {Component, Input, OnInit} from '@angular/core';
import {GalleryItem, ImageItem} from "ng-gallery";
import {PostPictureModel} from "../../../core/domain/post-picture-model";

@Component({
  selector: 'app-images-gallery',
  templateUrl: './images-gallery.component.html',
  styleUrls: ['./images-gallery.component.css']
})
export class ImagesGalleryComponent implements OnInit{
  @Input() thumbPosition: 'top' | 'left' | 'right' | 'bottom' = 'left';
  @Input() postPictureModels: PostPictureModel[] = [];
  galleryItems: GalleryItem[] = [];

  ngOnInit() {
    this.processImages();
  }

  processImages() {
      this.postPictureModels.forEach(postPicture => {
          this.galleryItems.push(
              new ImageItem({ src: (<PostPictureModel>postPicture).path.lg,
                                   thumb:  (<PostPictureModel>postPicture).path.sm
                  })
              )
          }
      );
    
  }
}
