import { UserRepository } from "../../core/repositories/user.repository";
import { map, Observable } from "rxjs";
import { environment } from "../../../environments/environment";
import { HttpClient, HttpParams } from "@angular/common/http";
import { UserEntity } from "../entity/user-entity";
import { UserRepositoryMapper } from "../mapper/user-repository.mapper";
import { inject, Injectable } from "@angular/core";
import { TokenService } from "../../shared/services/token.service";
import { RoleEnum } from "src/app/shared/enum/role.enum";


@Injectable({
    providedIn: 'root'
})
export class UserWebRepository extends UserRepository {

    userMapper = new UserRepositoryMapper();
    tokenService = inject(TokenService);

    constructor(private _http: HttpClient) {
        super();
    }

    get(email: string): Observable<any> {
        return this._http.get<UserEntity>(`${environment.baseURL}${environment.endpoints.usertype}/${email}`)
            .pipe(map(this.userMapper.mapFrom));
    }
    getType(email: string): Observable<any> {
        return this._http.get(`${environment.baseURL}${environment.endpoints.usertype}/${email}`)

    }

    put(form: any): Observable<any> {
        return this._http.put<any>(
            `${environment.baseURL}${this.tokenService.getTypeUser().toLowerCase()}/${environment.endpoints.userData}`,
            form
        )
    }
    signUp(email: string, role: RoleEnum, name: string, lastName: string) {
        const body = new HttpParams()
            .set('email', email)
            .set('role', role.toString()) // Asegúrate de convertir 'role' a string si es un enumerado
            .set('name', name)
            .set('lastName', lastName);
    
        return this._http.post(`${environment.baseURL}${environment.endpoints.createUser}`, body);
    }

    resetPassword(email: string): Observable<any> {
        return this._http.post(`${environment.baseURL}${environment.endpoints.createUser}`, { email });
    }

    getUser(email: string): Observable<any> {
        return this._http.get(`${environment.baseURL}${environment.endpoints.usersEmail}`, {params: { email } });
    }
    
    

}