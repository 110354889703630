import {NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';
import {SessionsRoutingModule} from './sessions-routing.module';
import {SessionSigninComponent} from './session-signin/session-signin.component';
import {SharedModule} from "../../shared/shared.module";
import {FormsModule, ReactiveFormsModule} from "@angular/forms";
import {SessionForgotPasswordComponent} from './session-forgot-password/session-forgot-password.component';
import {DialogService} from "../../shared/services/dialog.service";
import {SessionResetPasswordComponent} from './session-reset-password/session-reset-password.component';
import { UserUseCaseService } from 'src/app/core/usecase/user-use-case.service';


@NgModule({
    declarations: [
        SessionSigninComponent,
        SessionForgotPasswordComponent,
        SessionResetPasswordComponent
    ],
    imports: [
        CommonModule,
        FormsModule,
        ReactiveFormsModule,
        SessionsRoutingModule,
        SharedModule,
        
        
        
    ],
    providers: [
        DialogService,
        UserUseCaseService,
        
        
    ],
})
export class SessionsModule {
}
