import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';

import {SessionSigninComponent} from "./session-signin/session-signin.component";
import {SessionForgotPasswordComponent} from "./session-forgot-password/session-forgot-password.component";
import {SessionResetPasswordComponent} from "./session-reset-password/session-reset-password.component";


const routes: Routes = [
  {
    path: '',
    redirectTo: 'signin',
    pathMatch: 'full'
  },
  {
    path: 'signin',
    component: SessionSigninComponent,
    data: { title: 'Inicio de sesión' }
  },
  {
    path: 'forgot-password',
    component: SessionForgotPasswordComponent,
    data: { title: 'Recuperar Contraseña' }
  },
  {
    path: 'reset-password',
    component: SessionResetPasswordComponent,
    data: { title: 'Restablecer Contraseña' }
  }
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule]
})
export class SessionsRoutingModule { }
