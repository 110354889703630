<section class="max-height flex items-center justify-center">
    <div class="max-w-sm m-auto">
        <div class="p-6 pt-0 rounded-lg pad">
            <h1>¡Bienvenido! Ingresa tu correo electrónico</h1>
            <form class="grid grid-cols-1 gap-x-8 gap-y-8 " [formGroup]="formGroup">
                <ln-form-field-input
                    [label]="'Correo Electrónico'"
                    [control]="formGroup.controls.username"
                    [type]="'text'">
                </ln-form-field-input>
                <ln-form-field-password
                    class="mt-1"
                    [label]="'Contraseña'"
                    [control]="formGroup.controls.password">
                </ln-form-field-password>
            </form>
            <div class="mt-10">
                <ln-button-loading
                    [text]="'Continuar'"
                    [loading]="loading"
                    (clickButton)="login()"
                    [disable]="formGroup.invalid">
                </ln-button-loading>
            </div>
            <div class="flex items-center justify-center flex-col mt-5">
                <a [routerLink]="['/sessions/forgot-password']" class="text-sm cursor-pointer">Recupera tu
                    contraseña</a>
            </div>

        </div>
    </div>
</section>
