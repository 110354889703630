import { Component, OnInit } from '@angular/core';
import { TokenService } from '../../services/token.service';

@Component({
  selector: 'app-user-rol',
  templateUrl: './user-rol.component.html',
  styleUrls: ['./user-rol.component.css']
})
export class UserRolComponent implements OnInit {

  userType!: string

  constructor(private tokenService: TokenService,){}

  ngOnInit(): void {

    const type = this.tokenService.getTypeUser()

    switch (type) {
      case 'EXECUTIVE':
        this.userType = 'EJECUTIVO COMERCIAL';
        break;
      case 'PROPADMIN':
        this.userType = 'ADMINISTRADOR DE PROPIEDADES';
        break;
      default:
        this.userType = type;
    }

  }

}
