import {Directive, inject, Input, TemplateRef, ViewContainerRef} from '@angular/core';

import {TokenService} from "../services/token.service";


@Directive({
    selector: '[role]',
    standalone: true
})
export class RoleDirective {

    templateRef = inject(TemplateRef<any>)
    viewContainer = inject(ViewContainerRef)
    tokenService = inject(TokenService)

    @Input({required: true}) set role(role: 'EXECUTIVE' | 'PROPADMIN' | 'SUPERVISOR' | ['EXECUTIVE' | 'PROPADMIN' | 'SUPERVISOR', boolean?]) {
        let type
        let condition = true
        if (Array.isArray(role)) {
            type = role[0]
            condition = !!role[1]
        } else
            type = role
        this.tokenService.userIsType(type) && (condition === undefined ? true : condition)
            ? this.viewContainer.createEmbeddedView(this.templateRef)
            : this.viewContainer.clear()
    }
}
