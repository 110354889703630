import {Injectable} from '@angular/core';
import {HttpClient, HttpHeaders, HttpParams} from "@angular/common/http";

import {environment} from "../../../environments/environment";


@Injectable({
    providedIn: 'root'
})
export class AuthService {

    isLogin = false;

    constructor(
        private _http: HttpClient
    ) {
    }

    forgotPassword(email: string, captcha: string) {
        const headers = new HttpHeaders({
            'X-Recaptcha-Token': captcha
        });
    
        const params = new HttpParams().set('email', email);
    
        return this._http.post<any>(
            `${environment.baseURL}users/recovery-password`,
            {},
            { headers, params }
        );
    }

    signUpOrRecovery(email: string, captcha: string) {
        return this._http.post(
          `${environment.baseURL}${environment.endpoints.resetPassword}`,
          null,
          {
            headers: {
              'X-Captcha-Token': captcha,
            },
            params: {
              email,
            },
          }
        );
      }
}
