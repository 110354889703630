<div class="text-xs">
    <mat-label>{{ label }}</mat-label>
    <mat-form-field appearance="outline">
        <input
                matInput
                [type]="type"
                [placeholder]="placeholder"
                [formControl]="control"
                [min]="minimum"
        />
        <mat-icon *ngIf="haveIcon" matPrefix>{{ icon }}</mat-icon>
        <mat-hint>{{hintText}}</mat-hint>
        <mat-error *ngIf="control.hasError('required') && control.touched" class="form-error-msg">
            {{error}}
        </mat-error>
        <mat-error *ngIf="control.hasError('email') && control.touched" class="form-error-msg">
            Formato de correo inválido
        </mat-error>
        <mat-error *ngIf="control.hasError('cuiInvalid') && control.touched" class="form-error-msg">
            Formato de dpi inválido
        </mat-error>
    </mat-form-field>
</div>
