export const environment = {
  production: false,
  firebaseConfig: {
    apiKey: 'AIzaSyBeJBAckugwdEH8HzWrP7qv957f0bAscVg',
    authDomain: 'sza-preprod.firebaseapp.com',
    projectId: 'sza-preprod',
    storageBucket: 'sza-preprod.appspot.com',
    messagingSenderId: '359616739836',
    appId: '1:359616739836:web:f085ba457068ff90221a79',
  },
  TENANT_ID: 'dev-lunanueva-bo-jncyr',
  baseURL: 'https://dev.api.backoffice.properties.lunanuevaportal.com/api/v1/',
  endpoints: {
    session: 'session',
    cities: 'cities',
    propertyTypes: 'property-types',
    properties: 'properties',
    publisherPosts: 'posts',
    userData: 'user-data',
    users: 'user',
    log: 'log',
    usertype: 'user-type',
    createUser: 'users/sign-up',
    resetPassword: 'users/recovery-password',
    usersEmail: 'users/getEmail',
  },
  googleMapApiKey: 'AIzaSyCJx9U6UPxx8rNXXhVCdS3WK9RBvFf4HGc',
  recaptcha: {
    publicKey: '0x4AAAAAAACrvYbrkxvyQt_k',
  },
};
