import {Component, Inject, OnInit} from '@angular/core';
import {MAT_DIALOG_DATA, MatDialogRef} from "@angular/material/dialog";


@Component({
  selector: 'ln-dialog',
  templateUrl: './ln-dialog.component.html',
  styleUrls: ['./ln-dialog.component.css']
})
export class LnDialogComponent implements OnInit {

  title!: string
  subTitle!: string
  message!: string
  buttonOk!: string
  buttonCancel!: string
  oneButton=false
  constructor(
      @Inject(MAT_DIALOG_DATA) public data: any,
      private dialogRef: MatDialogRef<LnDialogComponent>
  ) {
  }

  ngOnInit() {
    
    this.oneButton=this.data.oneButton
    this.title = this.data.title
    this.subTitle = this.data.subTitle
    this.message = this.data.message
    this.buttonOk = this.data.buttonOk
    this.buttonCancel = this.data.buttonCancel
  }

  confirm(): void {
    this.dialogRef.close(true)
  }

  close() {
    this.dialogRef.close(false)
  }
}
