import {Observable} from 'rxjs';
import { RoleType } from 'src/app/shared/enum/role.enum';

export abstract class UserRepository {
    abstract get(email:string): Observable<any>;
    abstract getType(email:string): Observable<any>;
    abstract put(form: any): Observable<any> ;
    abstract signUp(email: string, role: RoleType, name: string,lastName: string):Observable<any>;
    abstract resetPassword(email:string):Observable<any>;
    abstract getUser(email:string):Observable<any>;
}
