<div class="header">
    <button mat-icon-button class="close-button" (click)="dialogRef.close(true)">
        <mat-icon class="close-icon">close</mat-icon>
    </button>
    <h2 class="text-center" matDialogTitle>Inicia sesión o regístrate</h2>
</div>
<mat-dialog-content class="mat-typography">
    <div class="text-center text-xl mt-4 ln-green">{{data.textHeader}}</div>
    <p class="text-center text-lg ln-green">Para guardar propiedades en tu lista de favoritos es necesario que
        previamente inicies sesión o te registres </p>
</mat-dialog-content>
<mat-dialog-actions align="center" class="mbs-16">
    <div class="mx-w sm:mr-8 sm:mb-0 mb-8 mr-0 ">
        <button mat-raised-button (click)="goToLoginPage()" color="primary" [mat-dialog-close]="true">Iniciar sesión</button>
    </div>
    <div class="mx-w">
        <button mat-stroked-button color="primary" class="registBtn" (click)="goToRegisterPage()" [mat-dialog-close]="true">Registrarme</button>
    </div>
</mat-dialog-actions>