import {Injectable} from '@angular/core';
import {Observable} from 'rxjs';
import {UserRepository} from "../repositories/user.repository";
import { RealModel } from '../domain/real_model';
import { UserModel } from '../domain/user-model';
import { RoleType } from 'src/app/shared/enum/role.enum';

@Injectable()

export class UserUseCaseService {

    constructor(private repository: UserRepository) {
    }

    get(email:string): Observable<any> {
        return this.repository.get(email);
    }
    getType(email:string): Observable<any> {
        return this.repository.get(email);
    }
    
    signUp(email: string, role: RoleType, name: string,lastName: string): Observable<any> {
        return this.repository.signUp(email,role,name,lastName);
    }

    resetPassword(email: string){
        return this.repository.resetPassword(email);
    }
    getUser(email: string){
        return this.repository.getUser(email);
    }
    
    
}