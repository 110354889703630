import { environment } from 'src/environments/environment';
import { Injectable } from '@angular/core';
import {
  signInAnonymously,
  Auth,
  onAuthStateChanged,
  User,
  UserCredential,
  signInWithEmailAndPassword,
  signOut
} from "@angular/fire/auth";
import {doc, getDoc, getFirestore} from "@angular/fire/firestore";
import { Observable } from 'rxjs';


@Injectable({
  providedIn: 'root',
})
export class FirebaseService {
  constructor(private auth: Auth) {
    auth.tenantId = environment.TENANT_ID;
  }

  async signInAnonymously(): Promise<UserCredential> {
    return await signInAnonymously(this.auth);
  }

  async signIn(email: string, password: string): Promise<User> {
    const { user } = await signInWithEmailAndPassword(
      this.auth,
      email,
      password
    );
    this._saveUserInStorage(user);
    if (user.emailVerified) await this.getFirebaseClientId();
    return user;
  }

  getToken(): Promise<string> {
    return new Promise((resolve) => {
      onAuthStateChanged(this.auth, (user) => {
        if (user) {
          user
            .getIdToken()
            .then((token) => resolve(token))
            .catch(() => resolve(''));
        } else {
          this.auth.currentUser
            ?.getIdToken(true)
            .then((token) => resolve(token))
            .catch(() => resolve(''));
        }
      });
    });
  }

  async getFirebaseClientId() {
    const docRef = doc(getFirestore(), 'config', 'client-id');
    const document = await getDoc(docRef);
    const client = document.data() ?? {};
    localStorage.setItem('client_id', client['client-id']);
}


  async doLogout() {
    await signOut(this.auth);
  }

  isAuthenticatedUser(): Observable<boolean> {
    return new Observable((observer) => {
      onAuthStateChanged(
        this.auth,
        (user) => {
          if (!user || user.isAnonymous || !user.emailVerified) {
            observer.next(false);
          } else {
            observer.next(true);
          }
        },
        () => observer.next(false)
      );
    });
  }

  private _saveUserInStorage(user: User) {
    const token = user && (user as any)['accessToken'];
    localStorage.setItem('token', token);
  }
}
