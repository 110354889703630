import {Injectable} from '@angular/core';
import {BehaviorSubject} from "rxjs";

import {ModalInterface} from "../interfaces/modal.interface";


@Injectable({
    providedIn: 'root'
})
export class ModalService {

    private modal = new BehaviorSubject<ModalInterface>({show: false})
    modal$ = this.modal.asObservable()

    show(title: string, description: string, type: 'SUCCESS' | 'WARNING' | 'ERROR' = 'SUCCESS', duration: number = 3) {
        this.modal.next({show: true, title, description, type, time: duration * 1000})
    }

}
