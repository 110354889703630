import {Component, EventEmitter, Output} from '@angular/core';
import {FiltersInterface} from "../../interfaces/filters.interface";

@Component({
  selector: 'ln-search-header',
  templateUrl: './search-header.component.html',
  styleUrls: ['./search-header.component.css']
})
export class SearchHeaderComponent {

  @Output() sortFilters = new EventEmitter<FiltersInterface>();

  setSortFilters(filters: FiltersInterface) {
    this.sortFilters.emit(filters);
  }
}
