import {inject} from "@angular/core";
import {CanActivateFn, Router} from "@angular/router";
import {map} from "rxjs";

import {FirebaseService} from "../services/firebase.service";


export const UserIsLogedIn: CanActivateFn = () => {
    const urlLogin = inject(Router).createUrlTree(['sessions/signin'])
    return inject(FirebaseService).isAuthenticatedUser()
        .pipe(map(isLogin => isLogin || urlLogin))
}
