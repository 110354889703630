import {Component} from '@angular/core';

declare global {
    interface Window {
        onloadTurnstileCallback: () => void;
        turnstile: {
            render: (idOrContainer: string | HTMLElement, options: any) => string;
            reset: (widgetIdOrContainer: string | HTMLElement) => void;
            getResponse: (widgetIdOrContainer: string | HTMLElement) => string | undefined;
            remove: (widgetIdOrContainer: string | HTMLElement) => void;
        };
    }
}

@Component({
    selector: 'app-root',
    templateUrl: './app.component.html',
    styleUrls: ['./app.component.css']
})
export class AppComponent {
  title = 'luna-nueva-manager-fe';
}
