<mat-card class=" custom-card"  [style.height.px]="height">
    <img mat-card-image class="custom-img " [ngSrc]="image" width="100" height="100" priority>
    <mat-card-content>
        <div class="w-[339px] min-h-[200px] mx-auto">
            <div class="mt-[15px]">
                <p class="a-secondary-color text-2xl font-medium">{{title}}</p>
            </div>
            <div>
                <p class="text-[15px] font-medium custom-text custom-text">{{textOne}}</p>
                <p class="text-[15px] custom-text font-normal">{{textTwo}}</p>
            </div>
           
        </div>
        <div class="flex justify-between mt-[25px]">
            <p *ngIf="dateInformation" class="text-[#B79086] text-sm font-normal min-w-[180px]">
                {{dateInformation}}
            </p>
            <p class="w-full a-secondary-color text-right text-sm font-medium">
                <a [routerLink]="[linkDetail, id]">{{linkText}}</a> 
            </p>
        </div>

    </mat-card-content>
</mat-card>