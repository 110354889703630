import { Injectable } from '@angular/core';
import { RoleEnum, RoleType } from "../enum/role.enum";

@Injectable({
  providedIn: 'root'
})
export class TokenService {

  public email!: string
  public typeUser!: string

  getToken() {
    const accessToken = localStorage.getItem('token')
    const base64 = accessToken?.split('.')[1]
   
    return base64 && JSON.parse(atob(base64))
  }

  fill(){
    this.typeUser=this.getTypeUser()
    this.email=this.getEmail()
  }
  getTypeUser() {
    const token = this.getToken()
    return token?.role
  }
  getEmail() {
    const token = this.getToken()
    return token?.email
  }

  userIsType(type: RoleType) {
    return type === this.getTypeUser()
  }
  isSupervisor() {
    if (this.getTypeUser() === RoleEnum.SUPERVISOR) {
      return true
    }
    else {
      return false
    }
  }

  userValid(){
    if(this.getTypeUser()===RoleEnum.PROPADMIN||this.getTypeUser()===RoleEnum.EXECUTIVE||this.getTypeUser()===RoleEnum.SUPERVISOR){
      return true
    }
    else {
      return false
    }
  }

}
