import {ChangeDetectionStrategy, Component, EventEmitter, Input, Output} from '@angular/core';

@Component({
    selector: 'ln-button-loading',
    templateUrl: './ln-button-loading.component.html',
    styleUrls: ['./ln-button-loading.component.css'],
    changeDetection: ChangeDetectionStrategy.OnPush
})
export class LnButtonLoadingComponent {

    @Input() loading!: boolean
    @Input() disable!: boolean
    @Input() text: string = 'Boton'
    @Input() textLoading: string = 'Cargando...'
    @Input() color: 'primary' | 'accent' | 'warn' = 'primary'

    @Output() clickButton = new EventEmitter<boolean>();

    onClick() {
        this.clickButton.emit(true);
    }
}
