import {ChangeDetectionStrategy, Component, Input, OnInit} from '@angular/core';
import {FormControl} from "@angular/forms";

@Component({
    selector: 'ln-form-field-input',
    templateUrl: './ln-form-field-input.component.html',
    styleUrls: ['./ln-form-field-input.component.css'],
    changeDetection: ChangeDetectionStrategy.OnPush
})

export class LnFormFieldInputComponent implements OnInit {

    @Input() control: FormControl = new FormControl<any>({});
    @Input() error = 'Campo requerido *';
    @Input() haveIcon = false;
    @Input() hintText = '';
    @Input() icon!: string;
    @Input() label = 'Label';
    @Input() placeholder!: string;
    @Input() type = 'text';
    @Input() minimum!: number;


    constructor() {
    }

    ngOnInit(): void {
    }

}
