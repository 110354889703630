import {Component, signal} from '@angular/core';
import {takeUntilDestroyed} from "@angular/core/rxjs-interop";
import {filter} from "rxjs";

import {ModalService} from "../../../services/modal.service";
import {ModalInterface} from "../../../interfaces/modal.interface";


@Component({
    selector: 'ln-alert',
    templateUrl: './ln-alert.component.html',
    styleUrls: ['./ln-alert.component.css']
})
export class LnAlertComponent {

    modalSignal = signal<ModalInterface | null>(null)
    effect = signal(false)
    timer!: NodeJS.Timeout

    get modal() {
        return this.modalSignal()
    }

    constructor(private modalService: ModalService) {
        this.modalService.modal$
            .pipe(
                filter(modal => modal.show),
                takeUntilDestroyed()
            )
            .subscribe(modal => {
                this.modalSignal.set(modal)
                this.close(modal.time || 3000)
            })
    }

    close(time: number) {
        if (this.timer) {
            clearTimeout(this.timer);
        }
        this.timer = setTimeout(() => {
            this.effect.set(true)
            setTimeout(() => {
                this.modalSignal.set(null)
                this.effect.set(false)
            }, 500)
        }, time)
    }

    getAlertDisplay(type: string | undefined) {
        switch (type) {
            case "WARNING":
                return 'warning'
            case "ERROR":
                return 'cancel'
            case "SUCCESS":
                return 'done'
            default:
                return 'cancel'
        }
    }

}
