<div class="text-xs">
    <button mat-raised-button
            [color]="color"
            [disabled]="loading || disable"
            (click)="onClick()">
        <div class="center-div">
            <span>{{ loading ? textLoading : text }}</span>
            <span matPrefix *ngIf="loading" class="ml-2">
                <mat-spinner diameter="20"></mat-spinner>
            </span>
        </div>
    </button>
</div>

