import {NgModule} from '@angular/core';
import {ClipboardModule} from '@angular/cdk/clipboard';
import {CommonModule, CurrencyPipe} from '@angular/common';
import {RouterModule, RouterOutlet} from "@angular/router";
import {GalleryModule} from "ng-gallery";
import {LightboxModule} from "ng-gallery/lightbox";
import { TranslateModule } from '@ngx-translate/core';
import {NavLayoutComponent} from './components/layouts/nav-layout/nav-layout.component';
import {AuthLayoutComponent} from './components/layouts/auth-layout/auth-layout.component';
import {MaterialModule} from "./modules/material.module";
import {LnComponentsModule} from "./components/ln-customs-material-components/ln-components.module";
import {HeaderComponent} from "./components/header/header.component";
import {LnAlertComponent} from './components/alerts/ln-alert/ln-alert.component';
import {FormsModule, ReactiveFormsModule} from "@angular/forms";
import {AuthStateDirectiveDirective} from './directives/auth-state-directive.directive';
import {AuthStateAnonymousDirective} from "./directives/auth-state-anonymous-directive";

import {SortPostsComponent} from "./components/sort-posts/sort-posts.component";
import {ImagesGalleryComponent} from './components/images-gallery/images-gallery.component';
import {LnIframeComponent} from './components/ln-iframe/ln-iframe.component';
import {LnDialogIframeComponent} from "./components/ln-dialog-iframe/ln-dialog-iframe.component";
import { LnDialogLoginRegistSessionComponent } from './components/ln-dialog-login-regist-session/ln-dialog-login-regist-session.component';
import {LnDialogComponent} from "./components/ln-dialog/ln-dialog.component";
import {SearchHeaderComponent} from "./components/search-header/search-header.component";
import { DragDropModule } from '@angular/cdk/drag-drop';
import { UserRolComponent } from './components/user-rol/user-rol.component';


const components = [
    AuthLayoutComponent,
    NavLayoutComponent,
    LnAlertComponent,
    HeaderComponent,
    AuthStateDirectiveDirective,
    AuthStateAnonymousDirective,
    SortPostsComponent,
    ImagesGalleryComponent,
    LnIframeComponent,
    LnDialogIframeComponent,
    LnDialogLoginRegistSessionComponent,
    LnDialogComponent,
    SearchHeaderComponent,
    UserRolComponent
]

const modules = [
    LnComponentsModule,
    ClipboardModule,
    MaterialModule,
    TranslateModule,
    DragDropModule
]

@NgModule({
    declarations: [...components],
    exports: [...components, ...modules],
    imports: [
        CommonModule,
        RouterOutlet,
        RouterModule,
        ReactiveFormsModule,
        FormsModule,
        GalleryModule,
        LightboxModule,
        ...modules
    ],
    providers: [
        CurrencyPipe,
    ]
})
export class SharedModule {
}
