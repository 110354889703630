<button [matMenuTriggerFor]="menu">
    <div class="flex flex-cols-2 items-center">
        <span [innerHTML]="textSortSelected"> </span>
        <mat-icon>keyboard_arrow_down</mat-icon>
    </div>

</button>
<mat-menu #menu="matMenu" xPosition="before">
    <button (click)="selectOption(options[0][0], options[0][1], options[0][2])" mat-menu-item>
        <span [innerHTML]="options[0][2]"></span>
    </button>
    <mat-divider></mat-divider>
    <button (click)="selectOption(options[1][0], options[1][1], options[1][2])" mat-menu-item>
        <span [innerHTML]="options[1][2]"></span>
    </button>
    <mat-divider></mat-divider>
    <button (click)="selectOption(options[2][0], options[2][1], options[2][2])" mat-menu-item>
        <span [innerHTML]="options[2][2]"></span>
    </button>
    <mat-divider></mat-divider>
    <button (click)="selectOption(options[3][0], options[3][1], options[3][2])" mat-menu-item>
        <span [innerHTML]="options[3][2]"></span>
    </button>
</mat-menu>