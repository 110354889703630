<mat-card class="custom-card" *ngIf="post.active">
        <img mat-card-image class="img-100"
             [ngSrc]="post.pictures[0] ? post.pictures[0].path.md : 'default.png'"
             width="100" height="100" priority
             [routerLink]="[post.id]">
    <mat-card-content>
        <div>
            
            <div id="publisherAndPriceRow" class="grid grid-cols-2 mt-4 flex-d">
                <div class="div-price">
                    <span>
                   {{post.property.price | currency: 'USD':'symbol':'1.0'}}
                    </span>
                </div>
                <a [routerLink]="[post.id]" id="seeMore" class="underline mr-6">Ver más </a>
            </div>
            <div class="col-span-2 mt-8">
                <div class="grid-rows-4">
                    <span id="detailsRow1" class="grid grid-cols-2 pb-2">
                        <div>
                            <img class="icons" src="../../../../../assets/icons/hotel.svg">
                            <span class="aligned-with-icon">&nbsp;{{ post.property.numberOfRooms }} Hab.</span>
                        </div>
                         <div>
                            <img class="icons" src="../../../../../assets/icons/ruler-square.svg">
                            <span class="aligned-with-icon">&nbsp;{{ post.property.totalArea }} M</span>
                         </div>
                    </span>
                    <span id="detailsRow2" class="grid grid-cols-2 pb-2">
                        <div>
                            <img class="icons" src="../../../../../assets/icons/car.svg">
                            <span class="aligned-with-icon">&nbsp;{{ post.property.numberOfParkings }} Parking</span>
                        </div>
                        <div>
                             <img class="icons" src="../../../../../assets/icons/shower.svg">
                                                    <span class="aligned-with-icon">&nbsp;{{ post.property.numberOfBathRooms }}
                                                        {{post.property.numberOfBathRooms > 1 ? 'baños' : 'baño'}}</span>
                        </div>
                    </span>
                    <div *ngIf="post.tour360" class="mt-2">
                        <a (click)="openIframeDialog(post.tour360.path)" class="underline virtual">Hacer tour
                            360º </a>
                    </div>
                    <div *ngIf="post.vr" class="mt-2">
                        <a (click)="openIframeDialog(post.vr.path)" class="underline virtual">Realidad
                            Virtual </a>
                    </div>
                    <div class="mt-4">
                        <span class="font-size-title">{{ post.property.propertyType.name | titlecase}}
                            en {{ post.property.purpose | lowercase }} </span>
                    </div>
                    <div class="mt-2 place-align">
                        <mat-icon>place</mat-icon>
                        <label class="font-size-city">{{post.property.address.city.name}}</label>
                    </div>
                    
                </div>
            </div>
        </div>
    </mat-card-content>
</mat-card>

