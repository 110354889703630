import { ChangeDetectionStrategy, ChangeDetectorRef, Component, OnInit, inject } from '@angular/core';
import { FormControl, FormGroup, Validators } from "@angular/forms";
import { Router } from "@angular/router";
import { FirebaseService } from "../../../shared/services/firebase.service";
import { ModalService } from "../../../shared/services/modal.service";
import { TokenService } from 'src/app/shared/services/token.service';
import { Firestore } from '@angular/fire/firestore';
import { HttpClient } from '@angular/common/http';
import { RoleEnum } from "../../../shared/enum/role.enum";
import { BreakPointService } from 'src/app/shared/services/break-point.service';
import { AuthService } from 'src/app/shared/services/auth.service';
import { UserWebRepository } from 'src/app/data/repository/user-web-repository';



export interface SessionFormControl {
    username: FormControl,
    password: FormControl,
}

@Component({
    selector: 'app-session-signin',
    templateUrl: './session-signin.component.html',
    styleUrls: ['./session-signin.component.css'],
    changeDetection: ChangeDetectionStrategy.OnPush
})
export class SessionSigninComponent implements OnInit {

    loading = false
    formGroup: FormGroup<SessionFormControl> = new FormGroup({
        username: new FormControl('', [Validators.required, Validators.email]),
        password: new FormControl('', Validators.required)
    })
    isLogin: any;
    userType: RoleEnum | undefined;
    constructor(
        private _firebase: FirebaseService,
        private _changeDetectorRef: ChangeDetectorRef,
        private _modal: ModalService,
        private _router: Router,
        private tokenService: TokenService,
        private firestore: Firestore,
        private _http: HttpClient,
        private breakpointService: BreakPointService,
        private auth: AuthService,
        private userRepository: UserWebRepository
    ) {


    }
    ngOnInit(): void {
        this._firebase.isAuthenticatedUser().subscribe(user => {
            this.isLogin = user;
            this.auth.isLogin = user;
            if (this.isLogin) {
                this._router.navigateByUrl('/my-account/home');
            }
        });

    }


    async login() {
        this.startLoading();
        const { username, password } = this.formGroup.value;
        await this._firebase.signInAnonymously();
        await this._firebase.getFirebaseClientId();

        try {
            this.userRepository.getUser(username).subscribe(
                (data: any) => this.handleUserResponse(data),
                (error: any) => this.handleRequestError(error)
            );
        } catch (error) {
            this.handleRequestError(error);
        }
    }

    private startLoading() {
        this.loading = true;
    }

    private stopLoading() {
        this.loading = false;
        this._changeDetectorRef.detectChanges();
    }

    private handleUserResponse(data: any) {
        let userType = (data.data.type);
        this.userType = userType;

        if (userType === RoleEnum.PROPADMIN || userType === RoleEnum.EXECUTIVE || userType === RoleEnum.SUPERVISOR) {
            this.handleSuccessfulLogin();
        } else {
            this.logoutAndRedirect('Permisos Incorrectos', 'Credenciales Inválidas');
        }

        this.stopLoading();
    }

    private async handleSuccessfulLogin() {
        const { username, password } = this.formGroup.value;
        if (this.breakpointService.isMobile && this.userType === RoleEnum.EXECUTIVE) {
            try {
                await this._firebase.signIn(username, password);
                this._router.navigateByUrl('/mobile/home');
            } catch (error) {
                this.handleRequestError(error);
            }

        } else if (!this.breakpointService.isMobile && this.userType) {
            try {
                await this._firebase.signIn(username, password);
                this._router.navigateByUrl('/my-account/home');
            } catch (error) {
                this.handleRequestError(error);
            }

        }
        else {
            this.logoutAndRedirect('Permisos Incorrectos', 'Credenciales Inválidas');
        }
    }

    private handleRequestError(error: any) {
        this.stopLoading();
        this._modal.show('Datos Incorrectos', 'Credenciales Inválidas', "ERROR", 5);
    }

    private logoutAndRedirect(errorMessage: string, errorDetail: string) {
        this._firebase.doLogout();
        this._router.navigateByUrl('/sessions/signin');
        this._modal.show(errorMessage, errorDetail, "ERROR", 5);
    }

}
