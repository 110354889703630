<div class="header">
    <button mat-icon-button class="close-button" (click)="dialogRef.close(true)">
        <mat-icon class="close-icon">close</mat-icon>
    </button>
    <h2 class="text-center" matDialogTitle>{{data.title}}</h2>
</div>
<mat-dialog-content class="mat-typography">
    <div class="text-center text-xl mt-4 ln-green">{{data.textHeader}}</div>
    <p class="text-center text-lg ln-green">{{data.textBody}}</p>
</mat-dialog-content>
<mat-dialog-actions align="center" class="mbs-16">
    <div *ngIf="data.buttonTextCancel" class="mx-w sm:mr-8 sm:mb-0 mb-8 mr-0 ">
        <button (click)="cancel()" mat-stroked-button color="primary" >{{data.buttonTextCancel}}</button>
    </div>
    <div class="mx-w">
        <button mat-raised-button color="primary" [mat-dialog-close]="true" cdkFocusInitial>{{data.buttonText ?? 'Aceptar'}}</button>
    </div>
</mat-dialog-actions>
