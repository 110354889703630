<nav  class="border-gray-200 rounded head "[ngClass]="{'header': showMenu===false,'header2':showMenu, 'hide':hide===true, 'unhide':hide===false}">
    <div class="items-center justify-between" [ngClass]="{'img': showMenu}">
        <div class="flex" >
            <a  *ngIf="isLogin " (click)="goMainPage()" class="flex items-center cursor-pointer">
                <img src="../../../../assets/icons/logo2.svg" class="h-16" alt="Logo" />
            </a>
            <a  *ngIf="!isLogin " href="/" class="flex items-center cursor-pointer">
                <img src="../../../../assets/icons/logo2.svg" class="h-16" alt="Logo" />
            </a>
        </div>

    </div>
    <div class="menu" *ngIf="isLogin ">
        <div class="btn">
            <button type="button" class="button"
                (click)="showMenuMobile()"><span class="sr-only">Abrir menu principal</span>
                <mat-icon aria-hidden="false" aria-label="Example home icon">{{ icon }}</mat-icon>
            </button>
        </div>
        <ng-container >
            <div class="flex flex-wrap items-center ">
                <div *ngIf="showMenu" class="textHeader">
                    <p class="userType">!Hola, {{this.userList}}!</p>
                    <p class="userEmail"> {{this.userEmail}}</p>
                </div>

            </div>

            <div class="w-full showMenu">

                <div class="items-center justify-between w-full  md:flex md:w-auto menuMobile"
                    [class.showMenu]="showMenu" id="navbar-cta">
                    <ul class="flex flex-col mt-4 md:flex-row md:mt-0 md:text-sm md:font-medium divide-y">
                        <hr>
                        <li *ngIf="isLogin">
                            <a [routerLink]="['mobile/profile']"
                                class="option"
                                aria-current="page">Mi perfil</a>
                        </li>
                        <hr>
                        <li *ngIf="isLogin">
                            <a (click)="openDialogCloseSession()"
                                class="option">
                               Cerrar sesión</a>
                        </li>

                    </ul>
                </div>
            </div>
        </ng-container>
    </div>

</nav>
<hr *ngIf="header && isLogin" class="mt-2 line">
