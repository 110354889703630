import {inject, Injectable} from '@angular/core';
import {BreakpointObserver, Breakpoints} from "@angular/cdk/layout";
import {take} from "rxjs";
import { Router } from '@angular/router';

@Injectable({
    providedIn: 'root'
})
export class BreakPointService {

    isMobile = false;

    private _size = inject(BreakpointObserver);

    constructor(
        private router: Router,
    ) {
        this._getViewSize();
    }

    private _getViewSize() {
        this._size.observe(['(max-width: 800px)'])
            .subscribe((result: { matches: any }) => {
                this.isMobile = result.matches;
                this.router.navigateByUrl(this.isMobile ? '/mobile' : '/my-account');
            });
    }
}
