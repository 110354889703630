import {Component, computed, inject, signal} from '@angular/core';
import { TranslationService } from '../../../services/translation.service';

@Component({
  selector: 'ln-translate',
  templateUrl: './ln-translate.component.html',
  styleUrls: ['./ln-translate.component.css']
})
export class LnTranslateComponent {

  translationService = inject(TranslationService)

  #lang = signal<string>(this.translationService.getCurrentLang());
  langLabel = computed(()=> this.#lang() === 'en' ? 'English' : 'Spanish');
  iconLanguage = computed(()=>
      this.#lang() === 'en'
          ? '../../../../assets/icons/english-flag.svg'
          : '../../../../assets/icons/spanish-flag.svg'
  );

  changeLang (): void {
    this.#lang.update(current => current === 'es' ? 'en' : 'es')
    this.translationService.useLang(this.#lang())
  }
}
