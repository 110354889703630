import { Component, HostListener, Input, OnDestroy, OnInit, inject } from '@angular/core';
import { Router } from "@angular/router";
import { MatDialog } from "@angular/material/dialog";
import { Subscription, take, timeout } from "rxjs";

import { FirebaseService } from '../../services/firebase.service';
import { AuthService } from "../../services/auth.service";
import { LnDialogComponent } from "../ln-dialog/ln-dialog.component";
import { TranslationService } from "../../services/translation.service";
import { locale as spanishLang } from './i18n/es';
import { TokenService } from '../../services/token.service';
import { BreakPointService } from '../../services/break-point.service';
import { UserInterface } from '../../interfaces/user.interface';
import { RoleEnum } from '../../enum/role.enum';


@Component({
    selector: 'app-header',
    templateUrl: './header.component.html',
    styleUrls: ['./header.component.css'],
})
export class HeaderComponent implements OnInit, OnDestroy {

    @Input() show: boolean = true;
    public showMenu: boolean = false;
    icon = 'menu';
    isLogin = false;
    subscriptions: Subscription[] = [];
    role!: string;
    header = false
    userType: RoleEnum | undefined;
    userList: any
    userEmail: any;
    hide = false
    constructor(
        private _dialog: MatDialog,
        private router: Router,
        private firebaseService: FirebaseService,
        private auth: AuthService,
        private translate: TranslationService,
        private breakpointService: BreakPointService,
        private tokenService: TokenService
    ) {
       

    }

    async ngOnInit(): Promise<void> {
        this.suscribe();
        await setTimeout(() => {
            this.tokenService.fill()
            this.role = this.tokenService.typeUser
            this.userEmail = this.tokenService.email
            this.userType = this.tokenService.typeUser as RoleEnum
            if (this.userType) {
                this.userList = this.getUsersByRole(this.userType);
            }
        }, 500);
        
    }

    ngOnDestroy() {
        this.unsuscribe();
    }

    suscribe() {
        const subscription = this.firebaseService.isAuthenticatedUser().subscribe(user => {
            this.isLogin = user;
            this.auth.isLogin = user;
            if (!this.breakpointService.isMobile && this.isLogin) { this.hide = true } else { this.hide = false }
            if ((this.breakpointService.isMobile) || (!this.breakpointService.isMobile && !this.isLogin)) {
                this.header = true;
            } else {
                this.header = false;
            }
        }
        )
        this.subscriptions.push(subscription);
    }

    unsuscribe() {
        this.subscriptions.forEach(s => {
            if (s) {
                s.unsubscribe()
            }
        });

        this.subscriptions = [];
    }
    @HostListener('window:resize')
    onWindowResize() {
        this.unsuscribe()
        this.suscribe()
    }

    public showMenuMobile() {
        this.showMenu = !this.showMenu;
        this.icon = this.showMenu ? 'close' : 'menu';
    }

    public goToHome() {
        this.router.navigateByUrl('/sessions/signin');
    }
    public goMainPage() {
        if (this.breakpointService.isMobile) {
            this.router.navigateByUrl('/mobile/home');
        } else {
            this.router.navigateByUrl('/my-account/home');
        }
    }

    public signOut() {
        localStorage.removeItem("token");
        this.firebaseService.doLogout();
        this.goToHome()
    }

    openDialogCloseSession() {
        this._dialog.open(LnDialogComponent, {
            width: '380px',
            data: {
                oneButton: false,
                title: '¿Estás seguro que quieres cerrar sesión?',
                buttonOk: 'Si, cerrar sesión',
                buttonCancel: 'No, volver'
            }
        })
            .afterClosed()
            .pipe(take(1))
            .subscribe(res => {
                if (res)
                    this.signOut()
            })
    }
    getUsersByRole(role: RoleEnum): string[] | undefined {
        switch (role) {
            case RoleEnum.SUPERVISOR:
                return ['Supervisor',];
            case RoleEnum.PROPADMIN:
                return ['Administrador de Propiedades'];
            case RoleEnum.EXECUTIVE:
                return ['Ejecutivo Comercial'];
            default:
                return undefined;
        }
    }
}
