import {ChangeDetectionStrategy, Component, EventEmitter, Input, Output} from '@angular/core';
import {FormControl} from "@angular/forms";

@Component({
    selector: 'ln-checkbox',
    templateUrl: './ln-checkbox.component.html',
    styleUrls: ['./ln-checkbox.component.css'],
    changeDetection: ChangeDetectionStrategy.OnPush
})
export class LnCheckboxComponent {
    @Input() checked!: boolean;
    @Input() label? = '';
    @Input() control: FormControl = new FormControl<any>({});

    @Output() onChange = new EventEmitter<any>();

    change(event: any): void {
        this.onChange.emit(event);
    }

}
