import {
    ChangeDetectionStrategy,
    Component,
    EventEmitter, inject,
    Input,
    OnInit,
    Output
} from '@angular/core';
import { Router } from "@angular/router";
import { take } from "rxjs";

import { AuthService } from "../../../services/auth.service";
import { DialogService } from "../../../services/dialog.service";
import { PropertyPostUseCaseService } from "../../../../core/usecase/property-post-use-case.service";
import { PropertyPostModel } from "../../../../core/domain/property-post-model";
import { ModalService } from "../../../services/modal.service";
import { FirebaseService } from "../../../services/firebase.service";
import { TokenService } from "../../../services/token.service";
import { MatDialog } from "@angular/material/dialog";

import { RealStateUseCaseService } from 'src/app/core/usecase/real-state-use-case.service';

@Component({
    selector: 'ln-card',
    templateUrl: './ln-card.component.html',
    styleUrls: ['./ln-card.component.css'],
    changeDetection: ChangeDetectionStrategy.OnPush
})
export class LnCardComponent implements OnInit {

    @Input() post!: PropertyPostModel
    @Input() showDeleteOption = false;
    @Output() favoritePostDeleted = new EventEmitter<Boolean>();
    @Input() showDeactivatedPost = false;
    @Output() postDeleted = new EventEmitter<string>();
    tokenService = inject(TokenService)
    role!: string;
    userId: any;
    localUserId: any;


    constructor(
        private _dialogService: DialogService,
    ) {
    }

    ngOnInit() {
        
        
    }

    openIframeDialog(path: string) {
        this._dialogService.openIframeDialog(path)
            .pipe(take(1))
    }

    
}
