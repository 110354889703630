import { Component } from '@angular/core';
import {Location} from "@angular/common";
@Component({
  selector: 'app-ln-back-button',
  templateUrl: './ln-back-button.component.html',
  styleUrls: ['./ln-back-button.component.css']
})
export class LnBackButtonComponent {
  constructor(
    private _location: Location
  ) {  }


  goBack() {
    this._location.back();
  }
}
