import {Component} from '@angular/core';
import {FormControl, FormGroup, ValidationErrors, ValidatorFn, Validators} from "@angular/forms";
import {ActivatedRoute, Router} from "@angular/router";
import {Auth, confirmPasswordReset, verifyPasswordResetCode} from "@angular/fire/auth";
import {take} from "rxjs";

import {ModalService} from "../../../shared/services/modal.service";


export interface SessionFormControl {
    password: FormControl,
    passwordRepeat: FormControl,
}

@Component({
    selector: 'app-session-reset-password',
    templateUrl: './session-reset-password.component.html',
    styleUrls: ['./session-reset-password.component.css']
})
export class SessionResetPasswordComponent {

    loading = false
    formGroup!: FormGroup<SessionFormControl>
    apiKey!: string
    mode!: string
    oobCode!: string

    constructor(
        private route: ActivatedRoute,
        private router: Router,
        private auth: Auth,
        private _modal: ModalService
    ) {
    }

    ngOnInit() {
        this.route.queryParams.pipe(take(1)).subscribe(({apiKey, continueUrl, mode, oobCode}) => {
            this.apiKey = apiKey
            this.mode = mode
            this.oobCode = oobCode
        })
        this.formGroup = new FormGroup({
            password: new FormControl('', [Validators.required]),
            passwordRepeat: new FormControl('', [Validators.required]),
        }, this.checkPasswordsForm)
        // this.formGroup.get('passwordRepeat')?.addValidators(this.checkPasswords)
        // this.formGroup.get('passwordRepeat')?.updateValueAndValidity()
    }

    // checkPasswords: ValidatorFn = (form): ValidationErrors | null => {
    //     const pass = this.formGroup.get('password')?.value
    //     return pass === form?.value ? null : {'not-same': true}
    // }

    checkPasswordsForm: ValidatorFn = (form): ValidationErrors | null => {
        const pass = form.get('password')?.value
        const passR = form.get('passwordRepeat')?.value
        return pass === passR ? null : {'not-same': true}
    }

    async reset() {
        if (!this.apiKey || !this.oobCode || !this.mode || this.mode !== 'resetPassword') {
            return
        }
        this.loading = true;
        try {
            const email = await verifyPasswordResetCode(this.auth, this.oobCode)
            if (email)
                await confirmPasswordReset(this.auth, this.oobCode, this.formGroup.controls.password.value);
            this.router.navigate(['sessions/signin']).then();
            this.loading = false;
        } catch (error) {
            this._modal.show(
                'Error cambiando contraseña',
                'Puede que este enlace haya expirado',
                "ERROR", 5
            )
            this.loading = false;
        }
    }

    

    get marginPass() {
        const form = this.formGroup.get('password')
        return Object.keys(form?.errors ?? {}).length > 2 && form?.touched ? 'mb-14'
            : Object.keys(form?.errors ?? {}).length > 1 && form?.touched ? 'mb-4' : '';
    }

    get marginPassRepeat() {
        const form = this.formGroup.get('passwordRepeat')
        return Object.keys(form?.errors ?? {}).length > 2 && form?.touched ? 'mb-12'
            : Object.keys(form?.errors ?? {}).length > 1 && form?.touched ? 'mb-8' : ''
    }

}
