import {ChangeDetectionStrategy, Component, Input} from '@angular/core';

@Component({
  selector: 'ln-card-maps',
  templateUrl: './ln-card-maps.component.html',
  styleUrls: ['./ln-card-maps.component.css'],
  changeDetection:ChangeDetectionStrategy.OnPush
})
export class LnCardMapsComponent {

  @Input() image: string = "";
  @Input() id: string = "";
  @Input() numberOfBedrooms: number = 0;
  @Input() numberOfBathrooms: number = 0;
  @Input() totalArea: number = 0;
  @Input() numberOfParkings: number = 0;
  @Input() price: string = "0";

}
