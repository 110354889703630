<div *ngIf="modal?.show" class="grid">
    <div
            [ngClass]="getAlertDisplay(modal?.type)"
            [class.blur-out-contract]="effect()"
            class="alert-content flex p-4 m-4 text-sm w-4/5 place-self-center">
        <div class="flex-d">
            <mat-icon class="sm:mr-4 mr-3 sm:mt-2 mt-4">{{getAlertDisplay(modal?.type)}}</mat-icon>
            <div class="font-medium">
                <ul>
                    <li>{{modal?.title}}</li>
                    <li>{{modal?.description}}</li>
                </ul>
            </div>
        </div>
    </div>
</div>
