<section class="max-height flex items-center justify-center">
    <div class="max-w-sm m-auto">
        <div class="p-8 rounded-lg">
            <h1 class="text-center">Ayuda con la contraseña</h1>
            <h4 class="text-center">Escribe la dirección de correo electrónico asociado a tu cuenta de Luna Nueva Manager</h4>
            <form class="grid grid-cols-1 gap-x-8 gap-y-2">
                <ln-form-field-input
                        [label]="'Correo Electrónico'"
                        [control]="username"
                        placeholder="ejemplo@gmail.com"
                        [type]="'email'"
                ></ln-form-field-input>
            </form>
            <div class="mt-4">
                <ln-button-loading
                        [text]="'Continuar'"
                        [loading]="loading"
                        [disable]="username.invalid"
                        (clickButton)="submitEmail()"
                >
                </ln-button-loading>
            </div>
        </div>
    </div>
</section>
