<div class="text-xs">
    <mat-label>{{ label }}</mat-label>
    <mat-form-field appearance="outline">
        <input matInput [type]="type" [formControl]="control"/>
        <mat-icon matSuffix class="cursor-pointer" (click)="showPassword()" matTooltip="Mostrar/Ocultar Contraseña">
            {{ icon }}
        </mat-icon>
        <mat-hint>{{hintText}}</mat-hint>
        <mat-error *ngIf="control.hasError('required') && control.touched" class="form-error-msg">
            Campo requerido *
        </mat-error>
        <mat-error *ngIf="control.hasError('password-invalid') && control.touched  && !control.pristine"
                   class="form-error-msg">
            La contraseña debe contener: 8 caracteres como mínimo, una letra mayúscula y un número.
        </mat-error>
        <mat-error *ngIf="control.hasError('not-same') && control.touched  && !control.pristine"
                   class="form-error-msg">
            Las contraseñas deben coincidir
        </mat-error>
    </mat-form-field>

</div>
