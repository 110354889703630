import {NgModule} from '@angular/core';
import {RouterModule, Routes} from '@angular/router';

import {AuthLayoutComponent} from "./shared/components/layouts/auth-layout/auth-layout.component";
import {NotFoundComponent} from "./views/not-found/not-found.component";
import {NavLayoutComponent} from "./shared/components/layouts/nav-layout/nav-layout.component";
import {UserIsLogedIn} from "./shared/guards/auth.guard";



const routes: Routes = [
    {
        path: '',
        redirectTo: '/sessions',
        pathMatch: 'full'
    },
    {
        path: '',
        component: AuthLayoutComponent,
        children: [
            {
                path: 'sessions',
                loadChildren: () => import('./views/sessions/sessions.module').then(m => m.SessionsModule),
                data: {title: 'Session'}
            }
        ]
    },
    {
        path: '',
        component: NavLayoutComponent,
        children: [
            {
                path: 'mobile',
                canActivate: [UserIsLogedIn],
                loadChildren: () => import('./views/mobile/mobile.module').then(m => m.MobileModule),
                data: {title: 'mobile'}
            },
            {
                path: 'my-account',
                canActivate: [UserIsLogedIn],
                loadChildren: () => import('./views/my-account/my-account.module').then(m => m.MyAccountModule),
                data: {title: 'Mi Cuenta'}
            },
           
        ]
    },
    {
        path: '**',
        component: NotFoundComponent
    }
];

@NgModule({
    imports: [RouterModule.forRoot(routes)],
    exports: [RouterModule]
})
export class AppRoutingModule {
}
