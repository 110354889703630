import {Component, EventEmitter, Output} from '@angular/core';
import {FiltersInterface} from "../../interfaces/filters.interface";
import {ActivatedRoute} from "@angular/router";

@Component({
  selector: 'app-sort-posts',
  templateUrl: './sort-posts.component.html',
  styleUrls: ['./sort-posts.component.css']
})
export class SortPostsComponent {

  @Output() sortFilters = new EventEmitter<FiltersInterface>();
  sortFiltersSelected: FiltersInterface = {};
  options = [
      ['price', 'asc', 'Ordenar por <span class="font-semibold"> Menor precio <span>'],
      ['price', 'desc', 'Ordenar por <span class="font-semibold"> Mayor precio</span>'],
      ['published_date', 'desc', 'Ordenar por <span class="font-semibold"> Más recientes</span>'],
      ['published_date', 'asc', 'Ordenar por <span class="font-semibold"> Más antiguas</span>'],
  ];
  textSortSelected: string = this.options[2][2];

  constructor(private _activatedRoute: ActivatedRoute) {
      this._activatedRoute.queryParams.subscribe(params => {
          if (params['sort'] === undefined) {
              this.textSortSelected = this.options[2][2];
          } else {
              this.sortFiltersSelected.sort = params['sort'];
              this.sortFiltersSelected.order = params['order'];
              const option = this.options.find(param => param[0] == params['sort'] &&  param[1] == params['order']);
              if (option) {
                  this.textSortSelected = option[2];
              }
          }
      })
  }

  selectOption(sort: string, order: string, text: string) {
    this.textSortSelected = text;
    this.sortFiltersSelected.order = order == 'desc' ? 'desc' : 'asc';
    this.sortFiltersSelected.sort = sort;
    this.sortFilters.emit(this.sortFiltersSelected);
  }
}
