<div class="color-ln flex justify-center items-center flex-col h-full">
    <div class="color-white c-404">404</div>
    <div class="color-white c-title">
        ¡Ups! No se encuentra esta página
    </div>
    <div class="color-white c-subtitle">
        Es posible que se haya eliminado la página que buscas
    </div>
    <button mat-raised-button class="button" [routerLink]="''">Ir al inicio</button>
</div>
