import {ChangeDetectionStrategy, ChangeDetectorRef, Component, DestroyRef, OnInit} from '@angular/core';
import {FormControl, Validators} from "@angular/forms";
import {Router} from "@angular/router";
import {finalize, take} from "rxjs";

import {AuthService} from "../../../shared/services/auth.service";
import {DialogService} from "../../../shared/services/dialog.service";
import {CaptchaService} from "../../../shared/services/captcha.service";
import {FirebaseService} from "../../../shared/services/firebase.service";
import {takeUntilDestroyed} from "@angular/core/rxjs-interop";
import { UserWebRepository } from 'src/app/data/repository/user-web-repository';


@Component({
    selector: 'app-session-forgot-password',
    templateUrl: './session-forgot-password.component.html',
    styleUrls: ['./session-forgot-password.component.css'],
    changeDetection: ChangeDetectionStrategy.OnPush
})
export class SessionForgotPasswordComponent {

  
    loading = false
    error!: string

    username: FormControl = new FormControl('', [Validators.required, Validators.email])

    constructor(
        private _auth: AuthService,
        private _changeDetectorRef: ChangeDetectorRef,
        private _captchaService: CaptchaService,
        private _dialogService: DialogService,
        private _firebase : FirebaseService,
        private _router: Router,
        private _destroyRef: DestroyRef,
        private _userService:UserWebRepository
    ) {
    }

    async submitEmail() {
        this.loading = true;
        const captcha: string = await this._captchaService.getCaptchaToken()
        await this._firebase.signInAnonymously();
        await this._firebase.getFirebaseClientId();
        this._auth.signUpOrRecovery(this.username.value, captcha)
            .pipe(
              takeUntilDestroyed(this._destroyRef),
              finalize(() => {
                this.loading = false
                this._changeDetectorRef.detectChanges()
            }))
            .subscribe({
                next: () => {
                    this._dialogService.openInfoDialog(
                        'Cerrar',
                        '',
                        'Dirígete a tu bandeja de entrada y sigue las instrucciones para restablecer tu contraseña. ',
                        'La solicitud de recuperar contraseña ha sido enviada a tu correo electrónico',
                    )
                        .pipe(take(1))
                        .subscribe(() => this._router.navigateByUrl('/sessions/signin'))
                },
                error: () => {
                    //TODO: mostrar error
                }
            })
    }
}
