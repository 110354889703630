<div class="text-xs">
    <mat-label>{{ label }}</mat-label>
    <mat-form-field appearance="outline">
        <mat-select [formControl]="control" multiple (selectionChange)="select($event)">
            <mat-option *ngFor="let item of list" [value]="item.value">{{item.key}}</mat-option>
        </mat-select>
    </mat-form-field>
    <mat-error *ngIf="control.hasError('required') && control.touched" class="form-error-msg">
        {{error}}
    </mat-error>
</div>