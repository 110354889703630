<section class="flex items-center justify-center sect">
    <div class="max-w-sm ml-auto mr-auto mb-auto mt-10 content pad">
        <div class="p-8 rounded-lg">
            <h1 class="text-center">Cambiar contraseña</h1>
            <h3 class="text-center">Escribe una nueva contraseña</h3>
            <h4>Debe contener 8 caracteres como mínimo, una letra mayúscula y un número</h4>
            <form class="grid grid-cols-1 gap-y-8" [formGroup]="formGroup">
                <div [ngClass]="marginPass">
                    <ln-form-field-password
                            [label]="'Contraseña'"
                            [control]="formGroup.controls.password"
                            [validatePassword]="true"
                    />
                </div>
                <div [ngClass]="marginPassRepeat">
                    <ln-form-field-password
                            [label]="'Repetir Contraseña'"
                            [control]="formGroup.controls.passwordRepeat"
                            [validatePassword]="true"
                            [validatePasswordRepeat]="true"
                    />
                </div>
            </form>
            <div class="mt-6">
                <ln-button-loading
                        text="Guardar"
                        [loading]="loading"
                        (clickButton)="reset()"
                        [disable]="formGroup.invalid"
                />
            </div>
        </div>
    </div>
</section>
